@font-face {
	font-family: "handwriting";
	src: url('/font.ttf');  
}

body {
  min-width:100%;
  box-sizing: border-box;
  margin: 0;
  font-family: "handwriting";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #000300;
}

.App {
  box-sizing: border-box;
	background: url("/bgpat.png");
	background-size: 500px;
	text-align: center;
	align-items: center;
	justify-content: center;
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.intro {
	transform: rotate(-2deg);
  background-color: #000300;
  max-width: 90%;
}

.body {
	width: 100%;
	font-size: 24px;
	margin-bottom: 16px;
}

.body-bottom {
	font-size: 24px;
	margin-top: 16px;
}

.content {
  box-sizing: border-box;
  width: 100%;
  background-color: #000300;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  font-size: 48px;
}

.catalogue {
  box-sizing: border-box;
  max-width: 800px;
	font-weight: 800;
	font-size: 24px;
	margin-top: 48px;
	margin-bottom: 48px;
	display: flex;
	flex-direction: column;
  background-color: #000300;
	padding-bottom: 48px;
	border-color: #ce3480;
	border-style: solid;
	padding: 16px;
	box-shadow: 20px 20px rgba(0, 0, 0, 0.5);
}

.catalogue.shut-up {
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
}

.catalogue.music-video {
  width: 500px;
  max-width: 90%;
}

.shut-up-cover {
  max-width: 300px;
}

.next {
	border-width: 12px;
}

.phase {
	transform: rotate(-2deg);
  box-sizing: border-box;
  max-width: 90%;
}

.fight-off {
	transform: rotate(1deg);
  box-sizing: border-box;
  max-width: 90%;
}

.demos {
	transform: rotate(-1deg);
  box-sizing: border-box;
  max-width: 90%;
}

.older {
	margin: 64px;
	transform: rotate(3deg);
  box-sizing: border-box;
  max-width: 90%;
}

.twenty-twenty-songs {
  width: 90%;
  max-width: 600px;
}

.twenty-twenty-songs iframe {
	margin-bottom: 8px;
}

.title {
	margin-bottom: 16px;
	font-size: 32px;
	text-decoration: underline;
}

.logo {
	width: 100%;
	max-width: 279px;
}

.footer {
	padding-top: 100px;
  color: white;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-bottom: 100px;
	font-size: 20px;
}

.footer a {
  color: #fefd54;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
